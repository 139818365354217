import React, {useState} from 'react';
import './Groups.scss';
import Checkout from '../Checkout/Checkout'
//import { Link, useLocation} from 'react-router-dom';
import { useUser } from "../../App";


export default function Groups(props){
    const { user, setUser } = useUser();
    const [contactFirstName,setContactFirstName] = useState('');
    const [contactLastName,setContactLastName] = useState('');
    const [contactEmail,setContactEmail] = useState('');
    const [contactPhone,setContactPhone] = useState('');
    const [groupFood,setGroupFood] = useState('');
    const [room,setRoom] = useState('');
    const [groupRate,setGroupRate] = useState('');
    const [draftId,setDraftId] = useState();
    const [readyForCheckout,setReadyForCheckout] = useState(false);
    const[paymentState,setPaymentState]=useState('none');
    const [completing,setCompleting] = useState(false);
    const [complete,setComplete] = useState(false);
    const [bookingDate,setBookingDate] = useState('');
    const [bookingTime,setBookingTime] = useState('');
    const [organization,setOrganization] = useState('');
    const [drinks,setDrinks] = useState(['','',''])
    const [attendance,setAttendance] = useState('')
    const [taxExempt,setTaxExempt] = useState(false);
    //const [deposit,setDeposit] = useState({amount:0})
    const [deposit] = useState({amount: 0});
    const [pizzaType,setPizzaType] = useState('');
    const [other,setOther] = useState('');
    /*
    const location = useLocation();
    console.log('location:',location)
    if(Object.keys(location.state).length>0){
        console.log('location:',location)
        if(location.state.contactFirstName && location.state.contactFirstName != contactFirstName){
            setContactFirstName(location.state.contactFirstName);
            location.state.contactFirstName = '';
        }
        if(location.state.contactLastName && location.state.contactLastName != contactLastName){
            setContactLastName(location.state.contactLastName);
            location.state.contactLastName = '';
        }
    }
    */
    const createDraftOrder = () => {
        var draftOrderBody = JSON.stringify({
            email: contactEmail,
            billingAddress:{
                firstName:contactFirstName,
                lastName:contactLastName,
                phone:contactPhone,
                company:organization,
                countryCode:"US"
            },
            lineItems: [{
                    title:"Group Booking",
                    quantity:1,
                    originalUnitPrice:0,
                    taxable:false,
                    requiresShipping:false,
                    sku:"",
                    customAttributes:[{
                        key:"Date",
                        value:bookingDate
                    },{
                        key:"Organization",
                        value:organization
                    },{
                        key:"Rate",
                        value:groupRate
                    },{
                        key:"Time",
                        value:bookingTime
                    },{
                        key:"Food",
                        value:groupFood
                    },{
                        key:"Room",
                        value:room
                    },{
                        key:"Pizza",
                        value:pizzaType
                    },{
                        key:"Drinks",
                        value:`${drinks[0]}${drinks[1]}${drinks[2]}`
                    },{
                        key:"Estimated Attendance",
                        value:attendance
                    },{
                        key:"Tax Exempt?",
                        value:`${taxExempt}`
                    },{
                        key:"Other Details",
                        value:other
                    },{
                        key:"Contact Info",
                        value:`${contactFirstName} ${contactLastName} - ${contactPhone} - ${contactEmail}`
                    }
                ]
                }]
        })
        console.log('draft body: ',draftOrderBody)
        fetch(`/api/create-draft-order`,{
            method: 'POST',
            body: draftOrderBody,
            headers: {'Content-type': 'application/json; charset=UTF-8',
            "Authorization": `Bearer ${sessionStorage.getItem('token')}`}
        })
        .then(res => res.json())
        .then(response => {
            if(response.data.draftOrderCreate.draftOrder){
                setDraftId(response.data.draftOrderCreate.draftOrder.id)
                setReadyForCheckout(true);
                togglePaymentState();
            } else {
                alert(JSON.stringify(response.data.draftOrderCreate.userErrors[0].message))
            }
        }) 
    }


    const togglePaymentState = () =>{
        if(paymentState === 'none'){
            setPaymentState('');
        } else{
            setPaymentState('none');
        }
    }

    const createCheckout = (amount) =>{
        if(readyForCheckout && amount >= 1 && paymentState !== "none"){
            return(<Checkout logout={logout} 
                            depositAmount={deposit} 
                            email={contactEmail} 
                            name={contactFirstName+' '+contactLastName} 
                            phone={contactPhone} id={draftId}/>)
        } else if(amount === 0){
        return(<button className="resbutton" onClick={completeOrder}>Create Booking Now</button>)
        } else {
            return(<p>Not ready for checkout...</p>)
        }
    }

    const logout = () =>{
        setUser({});
    }

    const completeOrder = () =>{
        if(!completing && !complete){
            setCompleting(true);
            fetch(`/api/complete-order`,{
                method: 'POST',
                body: JSON.stringify({
                    id: draftId
                }),
                headers: {'Content-type': 'application/json; charset=UTF-8',
                "Authorization": `Bearer ${sessionStorage.getItem('token')}`}
            })
            .then(res => res.json())
            .then(response => {
                if(response.data.draftOrderComplete.draftOrder){
                    setComplete(true);
                    alert('Group Confirmed!')
                    logout();
                } else {
                    alert(response.data.draftOrderComplete.userErrors[0].message)
                }
                setCompleting(false);
            }) 
        }
    }

    const handleDrinks = (e) =>{
        let drinks_ = drinks;
        drinks_[e.target.name-1] = e.target.value;
        console.log('drinks: ',drinks)
        setDrinks(drinks);
    }


    const pizzaQuestions = () =>{
        if(groupFood.includes('Pizza')){
            return(
                <div>
                    <b>Pizza Type</b><br/><select value={pizzaType} onChange={function(e){setPizzaType(e.target.value)}}>
                        <option value="">Select Pizza Type</option>
                        <option value="Pepperoni">Pepperoni</option>
                        <option value="Cheese">Cheese</option>
                        <option value="1/2 Pep 1/2 Ch" >1/2 Pep 1/2 Ch</option>
                        <option value="Other (see notes)">Other (See notes)</option>
                        
                    </select><br/><br/>
                    <b>Drinks</b><br/><select id="drinks-1" name="1" onChange={handleDrinks}>
                    <option value={drinks[0]||""}>{drinks[0]||"Drink Choice #1"}</option> 
                    <option value="Coke">Coke</option> 
                    <option value="Coke Zero">Coke Zero</option> 
                    <option value="Sprite">Sprite</option> 
                    <option value="Hi-C Pink Lemonade">Hi-C Pink Lemonade</option> 
                    <option value="Dr Pepper">Dr Pepper</option> 
                    <option value="Barq's Root Beer">Barq's Root Beer</option> 
                    <option value="Sweet Tea">Sweet Tea</option> 
                    <option value="Unsweet Tea">Unsweet Tea</option> 
                    <option value="Water Bottles">Water Bottles</option> 
                        
                </select><select id="drinks-2" name="2" onChange={handleDrinks}>
                    <option value={drinks[1]||""}>{drinks[1]||"Drink Choice #2"}</option>
                    <option value="Coke">Coke</option> 
                    <option value="Coke Zero">Coke Zero</option> 
                    <option value="Sprite">Sprite</option> 
                    <option value="Hi-C Pink Lemonade">Hi-C Pink Lemonade</option> 
                    <option value="Dr Pepper">Dr Pepper</option> 
                    <option value="Barqs Root Beer">Root Beer</option> 
                    <option value="Sweet Tea">Sweet Tea</option> 
                    <option value="Unsweet Tea">Unsweet Tea</option> 
                    <option value="Water Bottles">Water Bottles</option>    
                </select><select id="drinks-3" name="3" onChange={handleDrinks}>
                    <option value={drinks[2]||""}>{drinks[2]||"Drink Choice #3"}</option>
                    <option value="Coke">Coke</option> 
                    <option value="Coke Zero">Coke Zero</option> 
                    <option value="Sprite">Sprite</option> 
                    <option value="Hi-C Pink Lemonade">Hi-C Pink Lemonade</option> 
                    <option value="Dr Pepper">Dr Pepper</option> 
                    <option value="Barqs Root Beer">Root Beer</option> 
                    <option value="Sweet Tea">Sweet Tea</option> 
                    <option value="Unsweet Tea">Unsweet Tea</option> 
                    <option value="Water Bottles">Water Bottles</option>    
                </select><br/><br/>
                </div>
            )
        } 
    }


    return(
        <div className="groupwindow">
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
                <div className="groupselection">
                    <h1>Book a Group</h1>
                    <b>Date</b><br/><input type="date" value={bookingDate} onChange={function(e){setBookingDate(e.target.value)}}></input><br/><br/>
                    <b>Organization</b><br/><input type="text" value={organization} onChange={function(e){setOrganization(e.target.value)}}></input><br/><br/>
                    <hr/>
                    <h3>Customer Contact Info:</h3>
                    <b>First Name</b><br/><input type="text" value={contactFirstName} onChange={function(e){setContactFirstName(e.target.value)}}></input><br/><br/>
                    <b>Last Name</b><br/> <input type="text" value={contactLastName} onChange={function(e){setContactLastName(e.target.value)}}></input><br/><br/>
                    <b>Phone Number</b><br/><input type="tel" value={contactPhone} onChange={function(e){setContactPhone(e.target.value)}}></input><br/><br/>
                    <b>Email Address</b><br/><input type="email" value={contactEmail} onChange={function(e){setContactEmail(e.target.value)}}></input><br/><br/><br/>
                    <hr/>
                    <h3>Booking Info:</h3>
                    <b>Group Rate</b><br/>
                    <select value={groupRate} onChange={function(e){setGroupRate(e.target.value)}}>
                        <option value={groupRate}>{groupRate}</option>
                        <option default value="3 Hours - $20/Person">3 Hours - $20/Person</option>
                        <option default value="4 Hours - $22/Person">4 Hours - $22/Person</option>
                        <option value="5 Hours - $24/Person">5 Hours - $24/Person</option>
                        <option value="All Day - $26/Person">All Day - $26/Person</option>
                    </select>
                    <br/><br/>
                    <b>Start Time</b><br/><input type="text" value={bookingTime} onChange={function(e){setBookingTime(e.target.value)}}></input><br/><br/>
                    <b>Food Option</b><br/><select className="smallselect" value={groupFood} onChange={function(e){setGroupFood(e.target.value)}}>
                            <option value="None.">None</option>
                            <option value="2 Slices of Pizza Each (+7/Person)">2 Slices of Pizza(+$7/Person)</option>
                    </select><br/><br/>    

                    <b>Room Option</b><br/><select className="smallselect" value={room} onChange={function(e){setRoom(e.target.value)}}>
                            <option value="None.">None</option>
                            <option value="Midway Point (+$2, Min 50, Max 100)">Midway Point (+$2, Min 50, Max 100)</option>
                            <option value="Turning Point (+$2, Min 100, Max 250)">Turning Point (+$2, Min 100, Max 250)</option>
                    </select><br/><br/>    


                    {pizzaQuestions()}
                    <b>Estimated Attendance</b><br/><input type="text" value={attendance} onChange={function(e){setAttendance(e.target.value)}}></input><br/><br/>
                    <label><input type="checkbox" id="taxexempt" checked={taxExempt} onChange={function(e){setTaxExempt(e.target.checked)}}></input><b>Tax Exempt?</b></label><br/><br/>
                    <b>Other Notes</b><br/> <textarea value={other} rows="8" onChange={function(e){setOther(e.target.value)}}></textarea><br/><br/>
                    
                    <div className="remindersDiv">
                        <h3>Reminders</h3>
                        <input type="checkbox" id="exempt-check"></input><label htmlFor="exempt-check"><b>Tax Exempt Form Reminder</b><br/><i className="tinyText"> For tax exempt payments, please provide us with a tax exempt form.
                        </i></label><br/><br/>
                        <input type="checkbox" id="methods-check"></input><label htmlFor="methods-check"><b>Payment Methods Reminder</b><br/><i className="tinyText"> Group payments must be made as one transaction for the entire group. We accept cash, card, or organization checks. We do not accept personal checks.
                        </i></label><br/><br/>
                        <input type="checkbox" id="socks-check"></input><label htmlFor="socks-check"><b>Socks/Bathing Suit Reminder</b><br/><i className="tinyText">Some attractions at ZDT's require socks to participate. Please remind all of your group attendees to make sure to bring socks if they wish to participate in these attractions! The water rides may be open, weather permitting (zdtamusement.com/hours). If the water rides may be open, we recommend reminding your guests to bring a bathing suit as well. 
                        </i></label><br/><br/>
                        <div className="depositDiv">
                            <br/><br/>
                        <button onClick={createDraftOrder}>{deposit.amount>0?"Continue to Payment":"Continue to Booking Summary"}</button>
                        </div>
                    </div>
                </div>
                <div className="groupPayment" style={{display:paymentState}}>   
                    <h1>Booking Summary</h1><i>Please verify details before proceeding</i>
                    <h2>{bookingDate}</h2><h6>{bookingTime}</h6><h6>{organization}</h6>
                    <h6>{contactFirstName} {contactLastName}</h6> <h6>{contactPhone}</h6><h6>{contactEmail}</h6>
                    <h6>Rate and Length of Visit:{groupRate}</h6>
                    <h6>Est # of Participants: {attendance}</h6>
                    <h6>Room: {room}</h6>
                    <h6>Food Option: {groupFood}</h6>
                    <h6>Pizza Type: {pizzaType}</h6>
                    <h6>Drinks: {`${drinks[0]} ${drinks[1]} ${drinks[2]}`}</h6>
                    <h6>Tax Exempt?: {`${taxExempt}`}</h6>
                    <h6>Other Details: {other}</h6><br/>
                    <button className="backbutton" onClick={togglePaymentState}>Go Back</button><br/>
                    {createCheckout(deposit.amount)}
                </div>
        </div>
    )
}