import "./POSWindow.scss";
import ButtonGrid from "../ButtonGrid/ButtonGrid";
import OrderPanel from "../OrderPanel/OrderPanel";
import CustomerPanel from "../CustomerPanel/CustomerPanel";
import PaymentButtons from "../PaymentButtons/PaymentButtons";
import { POSProvider, usePOS } from "../../contexts/POSContext";
import React from "react";
import { useUser } from "../../App";

interface POSProps {
  directoryHandle: FileSystemDirectoryHandle;
  isBFF: boolean;
}

export default function POSWindow({
  directoryHandle,
  isBFF,
}: POSProps): JSX.Element {
  return (
    <POSProvider directoryHandle={directoryHandle} isBFF={isBFF}>
      <POSWindowContent />
    </POSProvider>
  );
}

function POSWindowContent(): JSX.Element {
  interface POSProps {
    directoryHandle: FileSystemDirectoryHandle;
    isBFF: boolean;
  }

  const {
    typedValue, setTypedValue,
    orderId, 
    fulfilled, setCurrentTab,
    searchMembers, searchOrders,
    handleKeyDown,
    attendanceCount,
    calendarAttendance, setRefreshAttendance,
    loadingMessages,
    inputRef
  } = usePOS();

  const { user } = useUser();

 
  return (
    <div key="window" className="poswindow">
      <div className="buttonheader">
        <h3>ZDT's Point of Sale</h3>
        {loadingMessages.length > 0 ? (
          <span className="loadingmsgs">{loadingMessages[0]}</span>
        ) : (
          <>
            <div className="attendance-row">
              {Object.entries(attendanceCount)
                .sort(([, a], [, b]) => (b as number) - (a as number))
                .map(([key, value]) => (
                  <span
                    key={key}
                    className="cashiername"
                    onClick={() => setRefreshAttendance(Math.random())}
                  >
                    {key}: {value as number}
                  </span>
                ))}
            </div>
            <div className="calendar-row">
              {Object.entries(calendarAttendance)
                .sort(([, a], [, b]) => (b as number) - (a as number))
                .map(([key, value]) => (
                  <span
                    key={key}
                    className="cashiername"
                    onClick={() => setRefreshAttendance(Math.random())}
                  >
                    {key}: {value as number}
                  </span>
                ))}
            </div>
          </>
        )}
        <div className="search-row">
          <input
            ref={inputRef}
            className="posInput"
            value={typedValue}
            onChange={(e) => setTypedValue(e.target.value)}
            onKeyDown={(event) => handleKeyDown(event as React.KeyboardEvent<HTMLInputElement>)}
          />
          {user.front && (
            <>
              <button
                className="searchbutton"
                onClick={(_e) => {
                  searchOrders(typedValue);
                  setCurrentTab(6);
                }}
              >
                Orders
              </button>
              <button className="searchbutton" onClick={(_e) => searchMembers(typedValue)}>
                Members
              </button>
            </>
          )}
        </div>
      </div>
      <div className={"buttonpanel " + (orderId && "hidebuttonpanel")}>
        <ButtonGrid />
      </div>
      <div className="orderheader">
        <CustomerPanel />
      </div>
      <div
        className={
          "receipt " +
          (orderId && "bigreceipt") +
          (fulfilled == "fulfilled" && "fulfilled")
        }
      >
        <OrderPanel />
      </div>
      <PaymentButtons />
    </div>
  );
}
