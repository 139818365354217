import React, { useState, useEffect, useRef } from "react";
import "./OrderPanel.scss";
import * as Types from "../POSWindow/POSTypes";
import { usePOS } from "../../contexts/POSContext";

export default function OrderPanel() {
  const {
    thisOrderItems,
    setThisOrderItems,
    orderNumber,
    subtotalPrice,
    setSubtotalPrice,
    setTaxLines,
    totalPrice,
    setTotalPrice,
    transactions,
    orderId,
    clearOrder,
    undoFul,
    fulfilled,
    refunds,
    setTriggerReset,
    triggerReset,
    discountApplications,
    discountCodes,
    orderNotes,
    lineItems,
    setLineItems,
    shopifyDiscountCodes,
    totalTaxAmount,
    setTotalTaxAmount,
    fulfillments,
    taxExempt,
    soundManager,
    isOrderCancelled,
  } = usePOS();


  useEffect(() => {
    const myDiv = document.getElementById("itemsfororder");
    if (myDiv) {
      myDiv.scrollTop = myDiv.scrollHeight;
    }
  });

  const [editItemIndex, setEditItemIndex] = useState(-1);
  const [editItemValue, setEditItemValue] = useState("");
  const [editAttrIndex, setEditAttrIndex] = useState(-1);
  const [editAttrSubIndex, setEditAttrSubIndex] = useState(-1);
  const [newAttrName, setNewAttrName] = useState("");
  const [newAttrValue, setNewAttrValue] = useState("");

  const [contextMenu, setContextMenu] = useState({
    isVisible: false,
    x: 0,
    y: 0,
    index: -1,
  });

  useEffect(() => {
    setContextMenu({
      isVisible: false,
      x: 0,
      y: 0,
      index: -1,
    });
    setEditItemIndex(-1);
    setEditItemValue("");
    setEditAttrIndex(-1);
    setEditAttrSubIndex(-1);
    setNewAttrName("");
    setNewAttrValue("");
  }, [triggerReset]);

  const handleRightClick = (event, index) => {
    //$1("right click:", index);
    event.preventDefault(); // Prevent the default context menu
    setContextMenu({
      isVisible: true,
      x: event.clientX,
      y: event.clientY,
      index,
    });
  };

  const toggleTaxable = () => {
    const newItems = [...thisOrderItems];
    const item = newItems[contextMenu.index];
    item.taxable = !item.taxable;
    setThisOrderItems(newItems);
    setContextMenu({ ...contextMenu, isVisible: false });
  };

  const handleContextMenuClose = () => {
    setContextMenu({ isVisible: false, x: 0, y: 0, index: -1 });
  };

  useEffect(() => {
    if (orderId) {
      // For online orders, we have discount_allocations directly on the line items.
      const updatedItems = JSON.parse(JSON.stringify(thisOrderItems)) as Types.ExtendedLineItem[];
      // Apply any line item discounts that come from Shopify's order
      updatedItems.forEach((item) => {
        if (item.discount_allocations && item.discount_allocations.length > 0) {
          item.discount_allocations.forEach((da) => {
            const discountAmount = parseFloat(da.amount);
            // Ensure properties array exists
            if (discountAmount > 0){
              if (!item.properties) {
                item.properties = [];
              }
              // Add a property to show the discount on the UI
              item.properties.push({
                name: `Discount`,
                value: `-$${discountAmount.toFixed(2)}`
              });
    
              // Reduce the item's price by the allocated discount amount. 
              // Note: discount_allocations amount is total discount for this line item, not per unit.
              // We'll divide by quantity to reduce per unit price consistently.
              const perUnitDiscount = discountAmount / item.quantity;
              item.price = Number((item.price - perUnitDiscount).toFixed(2));
            }
            
          });
        }
      })
  
      setLineItems(updatedItems);

    } else {
      let subtotalCalc = 0;
      let totalTaxCalc = 0;
      //let totalDiscountValue = 0;
    
      const lineItemsCopy: Types.ExtendedLineItem[] = JSON.parse(JSON.stringify(thisOrderItems));
      const shippingDiscountCodes = discountCodes.filter((discount) => discount.type === "shipping");
      const itemDiscountCodes = discountCodes.filter((discount) => discount.type !== "shipping");
    
      let subtotalPreDiscount = thisOrderItems.reduce((acc, item) => {
        if (!item.no_discounts) {
          return acc + item.fullPrice * item.quantity;
        } else {
          return acc;
        }
      }, 0);
    
      // Prepare totals on discount codes
      for (const discount of itemDiscountCodes) {
        discount.totalAmount = discount.amount;
      }
    
      const rebuiltLineItems: Types.ExtendedLineItem[] = [];
    
      // Process each line item once, applying all discounts before deciding to split
      for (const originalItem of lineItemsCopy) {
        if (originalItem.no_discounts) {
          // If no discounts apply, just push the item as-is
          rebuiltLineItems.push(originalItem);
          continue;
        }
    
        // Apply all discounts (percentage and fixed) to determine final price adjustments
        const item = { ...originalItem };
        if (!item.properties) {
          item.properties = [];
        }
    
        // Track total percentage discount amount and total fixed discount in cents
        let totalPercentageDiscount = 0;
        let totalFixedDiscountCents = 0;
    
        for (const discount of itemDiscountCodes) {
          if (discount.type === "percentage" && discount.amount) {
            let discountValue = discount.amount;
            if (discount.categories) {
              for (const category of discount.categories) {
                if (item.vendor === category.category) {
                  discountValue = category.discount;
                }
              }
            }
            const thisDiscountAmount = (item.price * discountValue) / 100;
            totalPercentageDiscount += thisDiscountAmount;
          } else if (discount.type === "fixed_amount" && discount.amount) {
            // Calculate this item's share of the discount in cents
            const itemFullPriceTotal = item.fullPrice * item.quantity;
            const itemShareOfDiscount = (itemFullPriceTotal / subtotalPreDiscount) * discount.totalAmount;
            const thisDiscountCents = Math.round(itemShareOfDiscount * 100);
    
            // Reduce the discount's pool after allocation
            subtotalPreDiscount -= item.price * item.quantity;
            discount.totalAmount -= itemShareOfDiscount;
    
            totalFixedDiscountCents += thisDiscountCents;
          }
        }
    
        // First, apply the percentage discount to the item's price
        if (totalPercentageDiscount > 0) {
          // Add property for percentage discount
          item.properties.push({
            name: "discount",
            value: `${((totalPercentageDiscount / item.price) * 100).toFixed(0)}% OFF`,
          });
          // Apply percentage discount
          item.price = Number((item.price - totalPercentageDiscount).toFixed(2));
          //totalDiscountValue += totalPercentageDiscount * item.quantity;
        }
    
        // Next, handle the fixed amount discount if any
        if (totalFixedDiscountCents > 0) {
          // Divide fixed discount among items
          const quantity = item.quantity;
          const baseDiscountPerItemCents = Math.floor(totalFixedDiscountCents / quantity);
          const remainder = totalFixedDiscountCents % quantity;
    
          const baseDiscountPerItem = baseDiscountPerItemCents / 100;
          const plusOneCount = remainder; // how many items get an extra cent
    
          // If no remainder, just apply the base discount to the whole item
          if (plusOneCount === 0) {
            // One line item, all get the same discount
            const discountedItem = {
              ...item,
              price: Number((item.price - baseDiscountPerItem).toFixed(2)),
              properties: [
                ...item.properties,
                {
                  name: "discount",
                  value: `$${baseDiscountPerItem.toFixed(2)} Off Each`,
                },
              ],
            };
            rebuiltLineItems.push(discountedItem);
            //totalDiscountValue += baseDiscountPerItem * quantity;
          } else {
            // We need to split the item into two parts: base and plusOne
            const baseCount = quantity - plusOneCount;
            const plusOneDiscount = (baseDiscountPerItemCents + 1) / 100;
    
            if (baseCount > 0) {
              const baseItem = {
                ...item,
                quantity: baseCount,
                price: Number((item.price - baseDiscountPerItem).toFixed(2)),
                properties: [
                  ...item.properties,
                  {
                    name: "discount",
                    value: `$${baseDiscountPerItem.toFixed(2)} Off Each`,
                  },
                ],
              };
              rebuiltLineItems.push(baseItem);
              //totalDiscountValue += baseDiscountPerItem * baseCount;
            }
    
            const remainderItem = {
              ...item,
              quantity: plusOneCount,
              price: Number((item.price - plusOneDiscount).toFixed(2)),
              properties: [
                ...item.properties,
                {
                  name: "discount",
                  value: `$${plusOneDiscount.toFixed(2)} Off Each`,
                },
              ],
            };
            rebuiltLineItems.push(remainderItem);
            //totalDiscountValue += plusOneDiscount * plusOneCount;
          }
        } else {
          // No fixed amount discount, no need to split, just push the (possibly percentage-discounted) item
          rebuiltLineItems.push(item);
        }
      }
    
      // Now recalculate subtotal & tax after applying all discounts and potential splits
      for (const item of rebuiltLineItems) {
        subtotalCalc += Number(item.price.toFixed(2)) * item.quantity;
        if (item.taxable && !taxExempt) {
          item.tax_lines = [];
          const itemTaxLine =
            Number((item.price * item.quantity * 1.0825).toFixed(2)) -
            Number(item.price.toFixed(2)) * item.quantity;
          item.tax_lines.push({
            title: "Sales Tax",
            rate: 0.0825,
            price: itemTaxLine,
          });
          totalTaxCalc += itemTaxLine;
        }
      }
    
      // Apply shipping discounts if needed
      for (const discount of shippingDiscountCodes) {
        // Fill in logic here if needed
      }
    
      const roundedSubtotal = toTwoDecimalPlaces(subtotalCalc);
      const roundedTax = toTwoDecimalPlaces(totalTaxCalc);
      const roundedTotal = toTwoDecimalPlaces(roundedSubtotal + roundedTax);
    
      setSubtotalPrice(roundedSubtotal);
      setTotalTaxAmount(roundedTax);
      setTaxLines([{ price: roundedTax, title: "Sales Tax", rate: 0.0825 }]);
      setTotalPrice(roundedTotal);
      setLineItems(rebuiltLineItems);
    
      //setTotalDiscountAmount(toTwoDecimalPlaces(totalDiscountValue));
      // setShopifyDiscountCodes(orderDiscountCodes);
    
    } // end else block
    
  }, [thisOrderItems, discountApplications, discountCodes, taxExempt]);

  function toTwoDecimalPlaces(num) {
    return Math.round(num * 100) / 100;
  }

  function printTotal() {
    const priceArray: React.JSX.Element[] = [];
    let transTotal = 0;
    //let totalDiscountValue = 0;

    shopifyDiscountCodes.forEach((discount, index) => {
      let discountValue = 0;
      if (discount.type === "percentage") {
        discountValue = toTwoDecimalPlaces(
          (discount.amount * subtotalPrice) / 100
        );
      } else {
        discountValue = discount.amount;
      }

      priceArray.push(
        <b key={"discdesc-code-" + index} className={`listItem`}>
          {discount.title || discount.code || "Discount"}
        </b>
      );
      priceArray.push(
        <b key={"discamt-code-" + index} className={`listItemPrice`}>
          -{formatCurrency(discountValue) + "\n"}
        </b>
      );
      //totalDiscountValue += discountValue;
    });

    /*
    shopifyDiscountCodes.forEach((discount, index) => {
      const discountValue = toTwoDecimalPlaces(discount.amount);
      priceArray.push(
          <b key={"discdesc-code-" + index} className={`listItem`}>
              {discount.title || discount.code || "Discount"}
          </b>
      );
      priceArray.push(
          <b key={"discamt-code-" + index} className={`listItemPrice`}>
              -{formatCurrency(discountValue) + "\n"}
          </b>
      );
      //totalDiscountValue += discountValue;
    });
    */

    priceArray.push(
      <b key={"sbtl"} className="listItem">
        SUBTOTAL
      </b>
    );
    priceArray.push(
      <b key={"sbtlamt"} className="listItemPrice">
        {formatCurrency(subtotalPrice) + "\n"}
      </b>
    );

    if (totalTaxAmount === 0) {
      priceArray.push(
        <b key={"taxdesc0"} className="listItem">
          SALES TAX
        </b>
      );
      priceArray.push(
        <b key={"taxamt0"} className="listItemPrice">
          {formatCurrency(0) + "\n"}
        </b>
      );
    } else {
      priceArray.push(
        <b key={"taxdesc"} className="listItem">
          SALES TAX (8.25%)
        </b>
      );
      priceArray.push(
        <b key={"taxamt"} className="listItemPrice">
          {formatCurrency(totalTaxAmount) + "\n"}
        </b>
      );
    }
    priceArray.push(
      <b key={"totaldesc"} className="listItem">
        TOTAL
      </b>
    );
    priceArray.push(
      <b key={"totalamt"} className="listItemPrice">
        {formatCurrency(totalPrice) + "\n"}
      </b>
    );

    transactions?.forEach((transaction, index) => {
      priceArray.push(
        <b
          key={"transdesc-" + index}
          className={`listItem ${
            transaction.kind === "sale" ? "redPayment" : ""
          }`}
        >
          {transaction.kind === "change" ? `Change` : transaction.gateway}
        </b>
      );
      priceArray.push(
        <b
          key={"transamt-" + index}
          className={`listItemPrice ${
            transaction.kind === "sale" ? "redPayment" : ""
          }`}
        >
          {formatCurrency(transaction.amount) + "\n"}
        </b>
      );
      if (transaction.kind === "sale") transTotal += transaction.amount;
      else if (transaction.kind === "refund") transTotal -= transaction.amount;
      else if (transaction.kind === "change") transTotal -= transaction.amount;
    });

    if (transTotal > 0) {
      priceArray.push(
        <b key={"duedesc"} className="listItem">
          Amount Due
        </b>
      );
      priceArray.push(
        <b key={"dueamt"} className="listItemPrice">
          {formatCurrency(totalPrice - transTotal) + "\n"}
        </b>
      );
    }
    return priceArray;
  }

  function formatCurrency(amount: number) {
    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  } //

  function clearItemX(e) {
    const clearnum = e.target.id.split("-")[1];
    const newOrder = [...thisOrderItems];
    newOrder.splice(clearnum, 1);
    setThisOrderItems([...newOrder]);
    //$1("item cleared!");
    soundManager.play("pop8");
    setTriggerReset(triggerReset + 1);
  }
  function clearAttribute(e) {
    const itemIndex = parseInt(e.target.id.split("-")[1]);
    const attrIndex = parseInt(e.target.id.split("-")[2]);
    const newOrder = [...thisOrderItems];
    newOrder[itemIndex].properties?.splice(attrIndex, 1);
    setThisOrderItems(newOrder);
    //$1("Attribute cleared!");
    soundManager.play("pop9");
  }

  function addNewAttribute(index: number) {
    // Create a deep copy of the item you wish to modify
    const itemCopy: Types.ExtendedLineItem = JSON.parse(
      JSON.stringify(thisOrderItems[index])
    );

    // Check if the item already has properties, if not, initialize it
    if (!itemCopy.properties) {
      itemCopy.properties = [];
    }
    // Add an attribute with default values
    itemCopy.properties.push({
      name: "Custom Attribute",
      value: "Default Value",
    });

    // Create a shallow copy of the order array
    const newOrder = [...thisOrderItems];
    // Replace the item at the specified index with the modified item copy
    newOrder[index] = itemCopy;

    // Update the order state
    setThisOrderItems(newOrder);
    // Set the newly added attribute to be edited
    setEditAttrIndex(index);
    setEditAttrSubIndex(itemCopy.properties?.length - 1);
  }

  function handleItemDoubleClick(index: number, attrIndex: number) {
    if (attrIndex !== undefined) {
      const attribute = thisOrderItems[index]?.properties?.[attrIndex];
      setEditAttrIndex(index);
      setEditAttrSubIndex(attrIndex);
      setNewAttrName(attribute?.name || "");
      setNewAttrValue(attribute?.value || "");
    } else {
      // This will reset the fields if we are adding a new attribute
      setNewAttrName("");
      setNewAttrValue("");
    }
  }

  function handleItemClick(i: number) {
    //move item to the bottom of the list
    const newOrder = [...thisOrderItems];
    newOrder.push(newOrder[i]);
    newOrder.splice(i, 1);
    setThisOrderItems(newOrder);
    setTriggerReset(triggerReset + 1)
  }

  function handleItemEditName(event) {
    setNewAttrName(event.target.value);
  }

  function handleItemEditValue(event) {
    setNewAttrValue(event.target.value);
  }

  function handleItemSave() {
    if (editAttrIndex !== -1) {
      const newOrder = [...thisOrderItems];
      if (newAttrValue === "") {
        // Remove the attribute if the Name or value is empty
        newOrder[editAttrIndex].properties?.splice(editAttrSubIndex, 1);
      } else {
        const newAttribute = {
          name: newAttrName || "Custom",
          value: newAttrValue,
        };
        setNewAttrName("");
        setNewAttrValue("");
        if (editAttrSubIndex === -1) {
          if (newOrder[editAttrIndex].properties) {
            newOrder[editAttrIndex].properties?.push(newAttribute);
          } else {
            newOrder[editAttrIndex].properties = [newAttribute];
          }
        } else {
          if (newOrder[editAttrIndex].properties) {
            const properties = newOrder[editAttrIndex].properties;
            if (properties?.[editAttrSubIndex]) {
              properties[editAttrSubIndex] = newAttribute;
            }
          }
        }
      }
      setThisOrderItems(newOrder);
      setEditAttrIndex(-1);
      setEditAttrSubIndex(-1);
    }
  }

  function isLineItemCanceled(itemId, refunds) {
    for (const refund of refunds) {
      if (refund.refund_line_items) {
        for (const refundLineItem of refund.refund_line_items) {
          if (refundLineItem.line_item_id === itemId) {
            return true;
          }
        }
      }
    }
    return false;
  }

  function printOrder(items: Types.ExtendedLineItem[]) {
    const orderArray = [];

    const openPopupWindow = (url, windowName, windowFeatures) => {
      window.open(url, windowName, windowFeatures);
      //$1("opening window!");
    };

    orderArray.push(
      <b
        key={"ordernum" + orderNumber}
        className={"listItem" + (isOrderCancelled ? " cancelled" : "")}
      >
        {orderId ? (
          <span>
            Order#:{" "}
            <span
              onClick={() =>
                openPopupWindow(
                  `https://admin.shopify.com/store/zdts-amusement-park/orders/${orderId}`,
                  "newWindow",
                  "width=800,height=600"
                )
              }
            >
              {orderNumber}
            </span>
          </span>
        ) : (
          `Order ${orderNumber ? `#${orderNumber}` : ""}`
        )}
        {!orderId && items?.length > 0 ? (
          <sup onClick={() => clearOrder(false, false)}>[x]</sup>
        ) : (
          <sup onClick={() => clearOrder()}>[x]</sup>
        )}
      </b>
    ); //order number header
    orderArray.push(<p key="ordernum-right" className="listItemPrice"></p>);
    if (items.length === 0) {
      return orderArray;
    }

    for (let i = 0; i < items?.length; i++) {
      const fulfillmentStatus = fulfillments.find(
        (fulfillment) =>
          fulfillment.line_items.some((item) => item.id === items[i].id) &&
          fulfillment.status === "success"
      )?.status;

      const isCanceled =
        fulfillmentStatus === "cancelled" ||
        isLineItemCanceled(items[i].id, refunds) ||
        isOrderCancelled;
      let fulfilled_quantity = 0;
      if (items[i].fulfillable_quantity >= 0) {
        fulfilled_quantity =
          (items[i].quantity || 0) - items[i].fulfillable_quantity;
      }

      if (orderId) {
        if (isCanceled) {
          orderArray.push(
            <p
              key={"orderline-cancelled-" + i}
              className={`listItem cancelled fulfilled`}
            >
              {items[i].quantity} x {items[i].title}
              {items[i].fullPrice > 0 || items[i].price > 0 ? (
                <>@ {formatCurrency(items[i].fullPrice || items[i].price)} </>
              ) : (
                ""
              )}
              {" (cancelled)"}
            </p>
          );
          orderArray.push(
            <p
              key={"lineprice-cancelled-" + i}
              className="listItemPrice cancelled fulfilled"
            >
              {formatCurrency(items[i].quantity * items[i].price) + "\n"}
            </p>
          );
        } else {
          if (items[i].fulfillable_quantity) {
            orderArray.push(
              <p key={"orderline-" + i} className={`listItem`}>
                {items[i].fulfillable_quantity} x {items[i].title}
                {items[i].fullPrice > 0 || items[i].price > 0 ? (
                  <>@ {formatCurrency(items[i].fullPrice || items[i].price)} </>
                ) : (
                  ""
                )}
              </p>
            );
            orderArray.push(
              <p key={"lineprice-" + i} className="listItemPrice">
                {formatCurrency(items[i].quantity * items[i].price) + "\n"}
              </p>
            );
          }

          if (fulfilled_quantity) {
            orderArray.push(
              <p key={"orderlinef-" + i} className={`listItem fulfilled`}>
                {fulfilled_quantity} x {items[i].title}
                {items[i].fullPrice > 0 || items[i].price > 0 ? (
                  <>@ {formatCurrency(items[i].fullPrice || items[i].price)} </>
                ) : (
                  ""
                )}
                {" (fulfilled)"}
                {
                  <sup
                    onClick={() => {
                      undoFul(items[i].id);
                    }}
                  >
                    [Unfulfill]
                  </sup>
                }
              </p>
            );
            orderArray.push(
              <p key={"linepricef-" + i} className="listItemPrice fulfilled">
                {formatCurrency(items[i].quantity * items[i].price) + "\n"}
              </p>
            );
          }
        }
      } else {
        // For new orders that do not have an orderId yet
        orderArray.push(
          <p
            key={"orderline-" + i}
            className={`listItem ${i === editItemIndex ? "editing" : ""}`}
            onDoubleClick={() => addNewAttribute(i)}
            onClick={() => handleItemClick(i)}
            onContextMenu={(e) => handleRightClick(e, i)}
          >
            {items[i].quantity} x{" "}
            {i === editItemIndex ? (
              <input
                type="text"
                value={editItemValue}
                onBlur={handleItemSave}
                autoFocus
              />
            ) : (
              items[i].title
            )}{" "}
            {items[i].fullPrice > 0 || items[i].price > 0 ? (
              <>@ {formatCurrency(items[i].fullPrice || items[i].price)} </>
            ) : (
              ""
            )}
            {!items[i].taxable ? "(NT)" : ""}
            {!orderId && (
              <sup id={"item-" + i}   
                onClick={(e) => {
                e.stopPropagation(); // Prevents the click from reaching <p>
                clearItemX(e);
              }}>
                [x]
              </sup>
            )}
          </p>
        );
        orderArray.push(
          <p
            key={"lineprice-" + i}
            className="listItemPrice"
            onContextMenu={(e) => handleRightClick(e, i)}
          >
            {formatCurrency(
              items[i].quantity * Number(Number(items[i].price).toFixed(2))
            ) + "\n"}
          </p>
        );
      }
      if (items[i].properties?.length) {
        const props = items[i].properties.map((att, index) => {
          if (!att.value || att.name.startsWith("_")) {
            return;
          }
          //$1('fulfilled_quantity', fulfilled_quantity)
          return (
            <>
              <p
                key={"modline-" + i + "-" + index}
                className={`listItem modifier 
                  ${items[i].refundedQuantity >= 0 ? "refunded" : ""}
                  ${fulfilled_quantity > 0 ? "fulfilled" : ""}
                  ${isCanceled ? "cancelled" : ""}`}
                  onDoubleClick={() => handleItemDoubleClick(i, index)}
              >
                {i === editAttrIndex && index === editAttrSubIndex ? (
                  <div className="input-group listItem modifier">
                    {newAttrName && (
                      <input
                        className="attribute-input-key"
                        type="text"
                        value={newAttrName}
                        maxLength={255}
                        onChange={handleItemEditName}
                        placeholder="key"
                        autoFocus
                      />
                    )}
                    <input
                      className="attribute-input-value"
                      type="text"
                      value={newAttrValue}
                      maxLength={255}
                      onChange={handleItemEditValue}
                      placeholder="value"
                    />
                    <button className="save-button" onClick={handleItemSave}>
                      Save
                    </button>
                  </div>
                ) : (
                  <>
                    {att.name != "Custom" && att.name != "Mod"
                      ? att.name + ": " + att.value
                      : att.value}
                    {!orderId && (
                      <sup
                        id={"attr-" + i + "-" + index}
                        onClick={clearAttribute}
                      >
                        [x]
                      </sup>
                    )}
                  </>
                )}
              </p>

              <p
                key={"modprice-" + i + "-" + index}
                className="listItemPrice"
              ></p>
            </>
          );
        });
        props.forEach((prop) => orderArray.push(prop));
      }
      if (items[i].variant_title) {
        orderArray.push(
          <>
            <p
              key={"variant-" + i}
              className={`listItem modifier 
              ${items[i].refundedQuantity >= 0 ? "refunded" : ""}
              ${!items[i].fulfillable_quantity ? "fulfilled" : ""}
              ${isCanceled ? "cancelled" : ""}`}
            >
              <>{items[i].variant_title}</>
            </p>

            <p key={"variantprice-" + i} className="listItemPrice"></p>
          </>
        );
      }
    } //add each item

    //add order notes if they exist
    orderNotes &&
      orderArray.push(
        <p key={"ordernotes"} className="notes">
          Order Notes: {orderNotes}
        </p>
      );

    return orderArray;
  }

  return (
    <div
      key="panel"
      className={
        fulfilled == "fulfilled"
          ? "orderGridWrapper fulfilled"
          : "orderGridWrapper"
      }
    >
      <div key="orderItems" className="orderGrid" id="itemsfororder">
        {printOrder(lineItems)}
      </div>
      <ContextMenu
        key="contextMenu"
        x={contextMenu.x}
        y={contextMenu.y}
        isVisible={contextMenu.isVisible}
        isTaxable={thisOrderItems[contextMenu.index]?.taxable}
        onToggleTax={toggleTaxable}
      />
      <div key="orderTotals" className="totalGrid">
        {printTotal()}
      </div>
    </div>
  );
}

function ContextMenu({ x, y, isVisible, isTaxable, onToggleTax }) {
  const menuRef = React.useRef(null);

  // Use effect to adjust the position if the menu goes off-screen
  React.useEffect(() => {
    if (!menuRef.current || !isVisible) return;

    const menuRect = menuRef.current.getBoundingClientRect();
    const maxX = window.innerWidth - menuRect.width;
    const maxY = window.innerHeight - menuRect.height;

    // Adjust the position of the context menu if it goes off the right or bottom edge of the screen
    if (x > maxX) {
      x = maxX;
    }
    if (y > maxY) {
      y = maxY;
    }

    menuRef.current.style.left = `${x}px`;
    menuRef.current.style.top = `${y}px`;
  }, [x, y, isVisible]);

  if (!isVisible) return null;

  return (
    <div
      ref={menuRef}
      className="context-menu"
      style={{ position: "absolute" }}
    >
      <ul className="context-menu-list">
        <li className="context-menu-item" onClick={onToggleTax}>
          {isTaxable ? "✓ Taxable" : "Taxable"}
        </li>
      </ul>
    </div>
  );
}
